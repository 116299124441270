/* Base theme variables */
:root {
  --primary: #6366f1;     /* Indigo */
  --primary-dark: #4338ca;
  --surface: #1e293b;     /* Dark slate */
  --background: #0f172a;  /* Darker slate */
  --text: #f8fafc;       /* Light text */
  --text-muted: #94a3b8;
  --success: #22c55e;    /* Green */
  --error: #ef4444;      /* Red */
  --warning: #eab308;    /* Yellow */
}

/* Base styles */
body {
  margin: 0;
  background: var(--background);
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  color: var(--text);
}

.container {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1000px) 1fr;
  grid-template-rows: min-content min-content auto;
  gap: 1rem;
  padding: 1rem;
  height: 100vh;
 }
 
 .container > * {
  height: min-content;
 }

.container > * {
  grid-column: 2;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo {
  height: 40px;
  width: auto;
}

/* Game layout */
.game-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  align-items: start;
  margin-top: 1rem;
}

.game-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.game-sidebar {
  position: sticky;
  top: 2rem;
}

/* Question styles */
.question {
  background: var(--surface);
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
}

/* Answer options */
.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button {
  background: var(--surface);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  color: var(--text);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.button-correct {
  background: var(--success);
  color: white;
}

.button.button-correct:hover {
  background: var(--success);
  color: white;
 }

.button-incorrect {
  background: var(--error);
  color: white;
}

.button.button-incorrect:hover {
  background: var(--error);
  color: white;
 }

/* Timer */
.timer-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: var(--surface);
  padding: 1rem;
  border-radius: 0.75rem;
  min-width: 200px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.timer-display {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.timer-bar {
  height: 4px;
  background: var(--primary);
  border-radius: 2px;
  transition: width 100ms linear;
}

/* Stats and info panels */
.info-panel {
  background: var(--surface);
  border-radius: 1rem;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.75rem;
}

.info-panel h3 {
  margin: 0 0 1rem 0;
  color: var(--text-muted);
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Leaderboard */
.leaderboard {
  composes: info-panel;
  margin-bottom: 1rem;
}

.leaderboard-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Username input */
.username-container {
  max-width: 400px;
  margin: 4rem auto;
  text-align: center;
}

.username-input {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--surface);
  color: var(--text);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.username-button {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  background: var(--primary);
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.username-button:hover {
  background: var(--primary-dark);
}

/* Fun fact */
.fun-fact {
  background: var(--surface);
  border-radius: 1rem;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 1rem;
}

.fun-fact h3 {
  margin: 0 0 0.5rem 0;
  color: var(--text-muted);
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: slideIn 0.3s ease forwards;
}

.game-progress {
  margin-bottom: 0.5rem;
  height: fit-content;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--surface);
  border-radius: 0.5rem;
 }

 .progress-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.question-count {
  font-weight: 500;
}

.reset-timer {
  margin-left: 2rem;
  color: var(--text-muted);
  position: relative;
}

.reset-timer::before {
  content: "•";
  position: absolute;
  left: -1rem;
  color: var(--text-muted);
}

.progress-bar-container {
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1.5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: var(--primary);
  transition: width 0.3s ease;
}

/* Responsive design */
@media (max-width: 768px) {
  .game-main {
    grid-template-columns: 1fr;
  }
  
  .game-sidebar {
    position: static;
  }
  
  .timer-container {
    position: static;
    margin-bottom: 1rem;
  }
}